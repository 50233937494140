.app {
  background-color: var(--primary-color, #edf2f8);
  font-family: var(--font-base);
}

.app__whitebg {
  background-color: var(--white-color, white);
}

.app__primarybg {
  background-color: var(--primary-color, #edf2f8);
}

.app__container {
  width: 100%;
  min-height: 100vh;
  max-width: 2000px;
  margin-inline: auto;

  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

.cursor__click {
  cursor: pointer;
}

.copyright {
  width: 100%;
  padding: 2rem 0 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  p {
    text-transform: uppercase;
    color: var(--black-color);
  }
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  text-transform: capitalize;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.9rem;
  text-align: left;
  color: var(--text-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}

.sayHi {
  color: var(--new-color);
  font-size: 16px;
  font-weight: 600;
  border: 2px solid var(--secondary-color);
  background-color: #1c2365;
  padding: 0.7em;
  position: relative;
  z-index: 2;
}

.sayHi::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  border-width: 35px;
  border-style: solid;
  border-color: var(--secondary-color) transparent transparent transparent;
  z-index: -1;
}

.app__social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  margin-inline: 1rem;

  button {
    // width: 40px;
    height: 40px;
    padding: 0.3rem;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;
    cursor: pointer;

    svg {
      // width: 20px;
      // height: 20px;
      font-size: 24px;
      color: var(--black-color);
      fill: var(--black-color);
      stroke: var(--black-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--new-color);
        fill: var(--new-color);
        stroke: var(--new-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.special__card {
  position: fixed;
  left: 0;
  bottom: 50px;
  width: fit-content;
  z-index: 100;
  background-color: var(--white-color);
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  border-radius: 0.3em;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.3);
  transform: translateX(-75%);
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.expand__card {
  transform: translateX(0px);
}

.rotate__arrow {
  transform: rotate(180deg);
}

.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1rem;

  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--gray-color);
    margin: 0.5rem;
    // position: relative;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--new-color);
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 676px) {
  .app__navigation,
  .app__social:not(.special__card):not(.footer__social) {
    display: none;
  }

  .copyright {
    padding: 2rem;
  }
}

@media screen and (min-width: 677px) {
  .app__social.footer__social {
    display: none;
  }
}
