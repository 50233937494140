#home {
  position: relative;
  //   background: url("../../assets/bgIMG.png");
  // background: url("../../assets/bgWhite.webp");
  // background-size: cover;
  // background-repeat: repeat;
  // background-position: center;

  .app__wrapper {
    padding: 0;
  }

  .copyright {
    display: none;
  }

  @media screen and (max-width: 767px) {
    background: none;
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 8rem 2rem 0;

  @media screen and (min-width: 2000px) {
    padding-top: 10rem;
  }

  @media screen and (max-width: 1340px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 7rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media screen and (max-width: 2000px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    background-color: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;
    position: relative;

    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    &::after {
      content: "";
      position: absolute;
      right: 20px;
      bottom: -30px;
      border: 15px solid;
      border-color: var(--secondary-color, #313bac) transparent transparent
        transparent;
    }
  }

  .badge-cmp h1:last-child {
    text-align: right;
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-align: left;
      color: var(--black-color);
      font-size: 1rem;
    }
  }

  span {
    font-size: 2.5rem;
    -webkit-animation: waveHand 0.5s infinite linear alternate;
    animation: waveHand 0.5s infinite linear alternate;
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1340px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@keyframes waveHand {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
}

.welcome {
  color: var(--black-color);
}

.hire {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-decoration: none;
  cursor: default;

  button {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: none;
    position: relative;
    font-weight: 500;

    background-color: var(--white-color, #ffffff);
    color: var(--black-color);
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);

    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
      -webkit-transform: translate(-200%, -50%);
      transform: translate(-200%, -50%);
      width: 100%;
      height: 100%;
      background-color: var(--secondary-color, #313bac);
      z-index: -1;
    }

    &:hover {
      color: var(--new-color);

      &::before {
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
}

.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background-color: var(--white-color, #ffffff);
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    img {
      width: 70%;
      height: 70%;
    }
  }

  div:nth-child(1) {
    width: 120px;
    height: 120px;
  }

  div:nth-child(2) {
    margin: 1.7rem;
    width: 170px;
    height: 170px;

    img {
      width: 75%;
      height: 75%;
    }
  }

  div:nth-child(3) {
    width: 90px;
    height: 90px;
  }

  @media screen and (min-width: 2000px) {
    div:nth-child(1) {
      width: 250px;
      height: 250px;
    }

    div:nth-child(2) {
      width: 370px;
      height: 370px;
    }

    div:nth-child(3) {
      width: 170px;
      height: 170px;
    }
  }

  @media screen and (max-width: 1340px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}

.app__header-img {
  // flex: 1;
  // height: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  .image__wrapper {
    display: grid;
    place-items: center;
  }

  .profile_img {
    width: 60%;
    object-fit: contain;
    z-index: 1;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(40%, black),
      to(rgba(0, 0, 0, 0))
    );
    -webkit-mask-image: linear-gradient(to bottom, black 40%, rgba(0, 0, 0, 0));
    mask-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(40%, black),
      to(rgba(0, 0, 0, 0))
    );
    mask-image: linear-gradient(to bottom, black 40%, rgba(0, 0, 0, 0));
    -webkit-transform: translateY(100px) scale(0);
    transform: translateY(100px) scale(0);
    animation: moveUp 0.5s 1s 1 linear forwards;
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 90%;
  }

  @media screen and (max-width: 1340px) {
    margin: 2rem 0;
  }
}

@keyframes moveUp {
  to {
    -webkit-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}
