// @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

html {
  --font-base: "Cairo", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #000;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --white-color: #ffffff;
  --new-color: #fdc500;
  --text-color: #272727;
  --nav-color-dark: #06d6a0;
}

html[data-theme="dark"] {
  --font-base: "Cairo", sans-serif;

  --primary-color: #000;
  --secondary-color: #313bac;
  --black-color: #fff;
  --lightGray-color: #000;
  --gray-color: #cfcfcf;
  --white-color: #121212;
  --new-color: #fdc500;
  --text-color: lightgray;
  --nav-color-dark: #06d6a0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Cairo", sans-serif;
  font-display: swap;

  &::-moz-selection {
    background-color: #000;
    color: #06d6a0;
  }
  &::selection {
    background-color: #000;
    color: #06d6a0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px var(--new-color);
  background: var(--black-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--new-color);
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cb9f01;
}

svg {
  font-size: 24px;
}
